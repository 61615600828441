<template>
  <div class="container">
    <!-- <Header /> -->
    <van-nav-bar
      title="搜索结果"
      left-arrow
      fixed
      placeholder
      @click-left="onClickLeft"
    />
    <div class="content">
      <div class="search-tips">
        <p>
          您搜索：<span style="color: #de006f">{{ value }}</span> 的结果
        </p>
      </div>
      <div class="wrapper">
        <div v-for="(item, index) in searchData" :key="index">
          <router-link :to="'/newsdetail/' + item.newsID">
            <div class="item">
              <div class="item-img">
                <img :src="imgUrl + item.yImgPath" />
              </div>
              <div class="item-title">
                {{ item.title }}
              </div>
              <div class="item-detail">
                <div class="detail-date">
                  {{
                    dateFormat("YYYY-mm-dd", item.ReleaseTime.substring(6, 19))
                  }}
                </div>
                <div class="detail-more">查看详情&nbsp;→</div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import baseURL from "../../utils/config";
export default {
  name: "SearchPage",
  data() {
    return {
      value: "",
      searchData: [],
      imgUrl: baseURL.imageUrl,
    };
  },
  components: {
    Header,
    Footer,
  },
  mounted() {
    this.value = this.$route.params.value;
    this.getSearch();
  },
  methods: {
    getSearch() {
      this.$api.getSearch(this.$route.params.value).then((res) => {
        this.searchData = res;
      });
    },
    dateFormat(fmt, date) {
      date = new Date(Number(date));
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
.van-nav-bar .van-icon {
  color: black;
}
.van-nav-bar__title {
  font-size: 18px;
  font-weight: bold;
}
.search-tips {
  height: 3rem;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  p {
    color: #4e4e4e;
    font-size: 1.1rem;
  }
}
.wrapper {
  margin: 1rem 0;
  .item {
    height: 12rem;
    margin: 0.5rem;
    padding: 0.5rem;
    border: solid #e2e2e2 0.1rem;
    .item-img img {
      width: 100%;
      height: 8rem;
    }
    .item-title {
      font-weight: bold;
      margin-top: 0.5rem;
      font-size: 0.9rem;
      color: #181818;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .item-detail {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 0.5rem;
      color: #9d9d9d;
      margin-right: 0.2rem;
      font-size: 0.8rem;
    }
  }
}
</style>